import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { boxHeight } from 'utils/boxHeight';
import { preloadImages } from 'utils/preloadingImages';

import { Box, Skeleton, SxProps, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';

import { newsDetailsPageAnimation } from 'pages/news/animation';

import { ReactComponent as Blog } from 'assets/main/news/Blog.svg';
import { ReactComponent as News } from 'assets/main/news/News.svg';
import ProgrammingLanguagesEighthSection from 'assets/main/news/pages/ProgrammingLanguagesEighthSection.webp';
import ProgrammingLanguagesFifhSection from 'assets/main/news/pages/ProgrammingLanguagesFifhSection.webp';
import ProgrammingLanguagesFirstSection from 'assets/main/news/pages/ProgrammingLanguagesFirstSection.webp';
import ProgrammingLanguagesFourthSection from 'assets/main/news/pages/ProgrammingLanguagesFourthSection.webp';
import ProgrammingLanguagesSecondSection from 'assets/main/news/pages/ProgrammingLanguagesSecondSection.webp';
import ProgrammingLanguagesSeventhSection from 'assets/main/news/pages/ProgrammingLanguagesSeventhSection.webp';
import ProgrammingLanguagesSixthSection from 'assets/main/news/pages/ProgrammingLanguagesSixthSection.webp';
import ProgrammingLanguagesThirdSection from 'assets/main/news/pages/ProgrammingLanguagesThirdSection.webp';
import { ReactComponent as BackArrow } from 'assets/news/BackArrow.svg';

import { languageBottomSection, pageTags } from './consts';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);
const images = [
  ProgrammingLanguagesEighthSection,
  ProgrammingLanguagesFifhSection,
  ProgrammingLanguagesFirstSection,
  ProgrammingLanguagesFourthSection,
  ProgrammingLanguagesSecondSection,
  ProgrammingLanguagesSeventhSection,
  ProgrammingLanguagesSixthSection,
  ProgrammingLanguagesThirdSection,
];
const Criteria = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
        mt: '24px',
        mb: '16px',
        color: theme.palette.orange.main,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
const Description = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: '14px', md: '16px' },
        fontWeight: { xs: 400, md: 500 },
        lineHeight: { xs: '21px', md: '24px' },
        textAlign: 'left',
        mb: { xs: '8px', md: '16px' },
        color: theme.palette.text.secondary,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};
const DescriptionWithChildren = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: '14px', md: '16px' },
        fontWeight: { xs: 400, md: 500 },
        lineHeight: { xs: '21px', md: '24px' },
        textAlign: 'left',
        mb: { xs: '8px', md: '16px' },
        color: theme.palette.text.secondary,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
const DescriptionAboveImg = ({
  textFirst,
  textSecond,
}: {
  textFirst: string;
  textSecond: string;
}) => {
  return (
    <>
      <Description
        sx={{
          fontSize: { xs: '14px', md: '20px' },
          fontWeight: 500,
          lineHeight: { xs: '21px', md: '30px' },
          textAlign: 'center',
          mb: { xs: '0px', md: '0px' },
          color: theme.palette.text.secondary,
        }}
        text={textFirst}
      />
      <Description
        sx={{
          fontSize: { xs: '12px', md: '14px' },
          fontWeight: { xs: 400, md: 400 },
          lineHeight: { xs: '16px', md: '21px' },
          textAlign: 'center',
          mb: { xs: '0px', md: '0px' },
          color: theme.palette.text.secondary,
        }}
        text={textSecond}
      />
    </>
  );
};
const DescriptionItems = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        mb: { xs: '8px', md: '8px' },
        ...sx,
      }}
    >
      <span
        style={{
          color: theme.palette.orange.main,
          fontSize: '40px',
          lineHeight: '14px',
          marginBottom: '5px',
        }}
      >
        •
      </span>
      <Typography
        sx={{
          fontSize: { xs: '14px', md: '16px' },
          fontWeight: { xs: 400, md: 500 },
          lineHeight: { xs: '21px', md: '24px' },
          textAlign: 'left',
          color: theme.palette.text.secondary,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
const DescriptionItemsBold = ({ textB, text }: { textB: string; text: string }) => {
  return (
    <Box
      sx={{
        mb: { xs: '8px', md: '8x' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '24px',
        }}
      >
        <span
          style={{
            color: theme.palette.orange.main,
            fontSize: '40px',
            lineHeight: '14px',
            marginBottom: '5px',
          }}
        >
          •
        </span>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: 700,
              lineHeight: { xs: '21px', md: '24px' },
              textAlign: 'left',
              color: theme.palette.text.secondary,
              textWrap: 'nowrap',
            }}
          >
            {textB}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: 400,
              lineHeight: { xs: '21px', md: '24px' },
              textAlign: 'left',
              color: theme.palette.text.secondary,
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const DescriptionItemsBoldRow = ({ textB, text }: { textB: string; text: string }) => {
  return (
    <Box
      sx={{
        mb: { xs: '8px', md: '8px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <span
          style={{
            color: theme.palette.orange.main,
            fontSize: '40px',
            lineHeight: '14px',
            marginBottom: '5px',
          }}
        >
          •
        </span>
        <Typography
          sx={{
            fontSize: { xs: '16px', md: '16px' },
            fontWeight: 700,
            lineHeight: { xs: '24px', md: '24px' },
            textAlign: 'left',
            color: theme.palette.text.secondary,
            textWrap: 'wrap',
          }}
        >
          {textB}
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {text}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};
const Languages = () => {
  const navigate = useNavigate();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const [shouldRenderImage, setShouldRenderImage] = useState(false);
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1025px)', () => {
      newsDetailsPageAnimation(boxHeight('.news_details_page_content'));
    });
  }, [boxHeight('.news_details_page_content'),shouldRenderImage]);
  useEffect(() => {
    (async () => {
      await preloadImages(images);
      setShouldRenderImage(true);
    })();
  });
  return (
    <Box
      id="news"
      className="news_details"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: {xs:'auto', md: '19900px' },
          p: { xs: '100px 16px', md: '140px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <FlyingBall
          clsName="news_details_page_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          bgClr={theme.palette.black.main}
          sx={{ right: '-20px', top: '200px' }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '49px', md: '17px' },
            right: '1px',
            pl: '60px',
          }}
        >
          <Blog
            style={{
              maxWidth: '848px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '114px',
            left: '8px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <News
            style={{
              maxWidth: '439px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          className="news_details_page_content"
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            minHeight: {xs:'auto', md: '19800px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Box
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              mb: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              cursor: 'pointer',
              width: '70px',
            }}
          >
            <BackArrow />
            <Typography
              sx={{
                fontSize: { xs: '16px', md: '16px' },
                fontWeight: 500,
                lineHeight: { xs: '24px', md: '24px' },
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }}
            >
              Back
            </Typography>
          </Box>
          <Typography
            sx={{
              margin: 'auto',
              fontSize: { xs: '24px', md: '48px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', md: '72px' },
              textAlign: 'center',
              mb: { xs: '32px', md: '54px' },
              maxWidth: '750px',
              color: theme.palette.text.secondary,
            }}
          >
            Best Programming Languages To Learn In 2025
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!shouldRenderImage ? (
              <Skeleton
                animation="pulse"
                variant="rectangular"
                sx={{
                  maxWidth: '1290px',
                  width: '100%',
                  height: 0,
                  paddingBottom: '47.8294%',
                  backgroundColor: 'rgba(0,0,0,0.44)',
                  borderRadius: '20px',
                }}
              />
            ) : (
              <Box
                sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px', }}
                component="img"
                src={ProgrammingLanguagesFirstSection}
              />
            )}
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'right',
                color: '#A3A3A3',
                mt: '8px',
              }}
            >
              November 10,2024
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontWeight: 500,
              lineHeight: { xs: '24px', md: '36px' },
              textAlign: 'left',
              mt: { xs: '24px', md: '32px' },
              mb: { xs: '16px', md: '32px' },
              color: theme.palette.text.secondary,
            }}
          >
            At We Can Develop IT, we are eager to share our expertise with you.
          </Typography>
          {[
            'The realm of programming languages is vast, teeming with hundreds of options that span countless tools and frameworks. Each has its unique merits and specific use cases. Yet, the reality is that many of these languages cater to niche markets or are only worthwhile if a lucrative opportunity comes along that demands their use — and if you’re willing to immerse yourself in that role or project.',
            'So, how does one navigate this labyrinth and determine which languages deserve your time and attention?',
            'The short answer: this guide is your roadmap. Simple, right?',
            'Jokes aside, the language you choose should align with your career aspirations, as some are clearly tailored for particular paths. But what if your career direction is still uncertain, or your main objective is securing employment, advancing professionally, or maximizing your earning potential? In that case, keep reading.',
            "We've sifted through data and collaborated with our team of expert instructors to curate a list of the 12 best programming languages to learn in 2024. The insights for this analysis were enriched by discussions with Kenn and Vit, our company’s visionary founders and true veterans of the IT world. Their perspectives were invaluable, steering this exploration of language trends.",
            'Our approach was systematic. We started by evaluating hundreds of programming languages, then narrowed the list to 25 contenders. Finally, we honed this down further using five key criteria:',
          ].map((item, ind) => (
            <Description sx={{ mb: { xs: '16px', md: '24px' } }} key={ind} text={item} />
          ))}
          {[
            [
              'Job Market Demand:',
              'Practicality is king — everyone needs a livelihood. We prioritized languages that boast at least 1,000 active job postings, acknowledging that most learners are pragmatic and seek skills that pay.',
            ],
            [
              'Average Salary:',
              'Will this language lead to a lucrative career? Only languages with average job listings surpassing $100,000 in salary made the cut.',
            ],
            [
              'Ease of Learning:',
              'Time, as they say, is money. How quickly can a new learner get up to speed with each language?',
            ],
            [
              'Developer Satisfaction:',
              'Enjoyment matters. A language worth investing in should be one that developers actually like working with.',
            ],
            [
              'Future Relevance:',
              'Is this language built to last? We considered how enduring each language is likely to be, whether it supports projects that will remain relevant over the next decade.',
            ],
          ].map((item, ind) => (
            <DescriptionItemsBold key={ind} text={item[1]} textB={item[0]} />
          ))}
          {[
            'Don’t overwhelm yourself by trying to master all these languages at once. If you’re new to programming, select one and commit to it. Jumping between languages in the early stages will only squander your valuable time.',
            'Already familiar with a language or two? Great. Read on to see if there’s one here that could elevate your existing skillset.',
          ].map((item, ind) => (
            <Description
              key={ind}
              sx={{
                fontSize: { xs: '14px', md: '16px' },
                fontWeight: { xs: 400, md: 500 },
                lineHeight: { xs: '21px', md: '24px' },
                textAlign: 'left',
                mt: { xs: '16px', md: '24px' },
                color: theme.palette.text.secondary,
              }}
              text={item}
            />
          ))}
          <Description
            sx={{
              fontSize: { xs: '16px', md: '20px' },
              fontWeight: { xs: 700, md: 700 },
              lineHeight: { xs: '24px', md: '30px' },
              mb: { xs: '16px', md: '24px' },
            }}
            text="The Winners for Each of Our 5 Selection Criteria"
          />
          <Criteria text="Criteria 1: Job Demand" />
          <DescriptionWithChildren>
            {`Job demand is a pivotal factor. After all, if there aren't ample job opportunities, investing time in learning a language could be an unproductive endeavor.`}
            <br />
            For this year’s analysis, we set a high bar. To make it onto our shortlist of 12
            languages, each needed to meet a minimum threshold: at least 1,000 job postings for
            U.S.-based roles on a single prominent job site. This criterion ensures that your
            efforts to master one of these languages translate to strong job prospects, even for the
            rising stars in the tech world.
          </DescriptionWithChildren>
          <Description
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: 600,
              lineHeight: { xs: '21px', md: '24px' },
              mb: { xs: '16px', md: '24px' },
            }}
            text="Which Language Had the Most Open Jobs?"
          />
          <DescriptionAboveImg
            textFirst="2024 Programming Language Breakdown"
            textSecond="# of open job postings for each language, in the US only"
          />
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '47.8294%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px' }}
              component="img"
              src={ProgrammingLanguagesSecondSection}
            />
          )}
          {[
            'Among the 12 finalists, there were nearly 450,000 job listings collectively — a testament to the strength of the programming job market. Leading the pack is Python, boasting a commanding 85,000 current job postings.',
            'This substantial demand is one of the key reasons we advocate for all beginners to start with Python (or JavaScript). The rationale is clear: Python’s job market is robust and growing, it maintains a high level of popularity, and it offers competitive compensation (with the 4th highest average salary, exceeding $120,000 annually).',
            'Collecting these insights was made efficient through data parsing, which allowed us to swiftly compile and analyze current job market data. This method ensured an up-to-date and comprehensive perspective, saving time while enhancing the reliability of our findings.',
            "Furthermore, Python's versatility is unparalleled — it powers projects in an array of industries and fields. For those looking to carve out a space in the AI boom or contribute to data science initiatives, Python remains a strategic choice.",
          ].map((item, ind) => (
            <Description key={ind} text={item} />
          ))}
          <Criteria text="Criteria 2: Average Salary (USD)" />
          <Description text="For this analysis, we focused only on programming languages with an average salary of at least $100,000 while ensuring that there were still plenty of job opportunities available. This benchmark did mean that some popular languages didn’t make the cut." />
          <Description text="For example:" sx={{ fontWeight: '700', mb: '4px' }} />
          <DescriptionWithChildren sx={{ mb: '8px' }}>
            <span style={{ fontWeight: 700 }}>VBA</span> is an excellent tool for data analysis and
            automation. Mastering VBA can open the door to numerous roles in today’s job market, but
            the average salary hovers around $82,000. Make no mistake, that’s a strong number, but
            we had to draw the line somewhere.
          </DescriptionWithChildren>
          <Description
            sx={{ mb: '8px' }}
            text="Moreover, being solely a “VBA developer” is uncommon. Instead, VBA is typically one skill among many in roles focused on data analysis or Business Intelligence."
          />
          <Description sx={{ mb: '8px' }} text="Common skills paired with VBA include:" />
          {[
            'Excel',
            'Power BI',
            'Time-series forecasting',
            'Maybe SQL',
            'Maybe Python',
            'Maybe Statistics',
          ].map((item, ind) => (
            <DescriptionItems sx={{ mb: '8px' }} key={ind} text={item} />
          ))}
          <Description text="If you’re interested in the data field, we recommend checking out our guide that breaks down the differences between Data Engineers, Data Analysts, and Data Scientists." />
          <DescriptionWithChildren>
            <span style={{ fontWeight: 700 }}>
              On the other end of the spectrum, there’s Solidity,
            </span>{' '}
            a language tied to the Web3 ecosystem. The industry faces a scarcity of skilled
            developers, presenting significant opportunities for those who specialize in it. With an
            average salary of $179,000 per year, it’s an appealing prospect.
          </DescriptionWithChildren>
          <Description text="However, Solidity didn’t meet our criteria due to the relatively low number of job postings — fewer than 300 across the U.S. But, if you have confidence in Web3, NFTs, and the potential resurgence of the crypto world, or if you simply have a strong interest in these fields, then by all means, go for it." />
          <Description
            sx={{ fontWeight: '700' }}
            text="Personally, I firmly believe in the future of Web3, blockchain, and the metaverse. From my perspective, this is the space to watch and invest in if you’re passionate about technology’s next frontier."
          />
          <Description text="As we mentioned earlier, the best programming language for you is the one aligned with your career aspirations. This guide aims to assist those who aren’t sure where to start, helping narrow down options based on salary and opportunity. If you have a specific goal, follow that path and master the language that supports it." />
          <Description
            sx={{ fontWeight: '700' }}
            text="So, which languages offer the highest average salaries?"
          />
          <DescriptionAboveImg
            textFirst="2024 Programming Language Breakdown"
            textSecond="Average Salary in USD"
          />
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '47.8294%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesThirdSection}
            />
          )}
          <DescriptionWithChildren>
            <span style={{ fontWeight: 700 }}>Ruby, TypeScript</span> and{' '}
            <span style={{ fontWeight: 700 }}> Kotlin </span> lead the way, with average annual
            salaries ranging from $128,000 to $135,000.
          </DescriptionWithChildren>
          <Description text="However, if you’re looking for a balance between job demand and income, Python, SQL  and Java are your top contenders:" />
          {[
            ['Python:', '86,000 open jobs | Average Salary: $123,000'],
            ['SQL:', '65,000 open jobs | Average Salary: $110,000'],
            ['Java:', '55,000 open jobs | Average Salary: $118,000'],
          ].map((item, ind) => (
            <DescriptionItemsBoldRow key={ind} text={item[1]} textB={item[0]} />
          ))}
          <Criteria text="Criteria 3: Learning Difficulty" />
          <Description
            sx={{ mb: { xs: '0px', md: '0px' } }}
            text="Not all programming languages are created equal when it comes to ease of learning. While some can be picked up quickly, others require more time and dedication."
          />
          <DescriptionWithChildren>
            Of course, assessing learning difficulty is subjective, so take this guide as exactly
            that — a guide. It’s not the final word, nor is it meant to incite debates in the
            comments! Instead, think of it as a helpful tool based on collective insights from our
            in-house experts, Kenn and Vit, alongside feedback from other seasoned developers at{' '}
            <span style={{ fontWeight: 700 }}>We Can Develop IT.</span>
          </DescriptionWithChildren>
          <Description
            sx={{ fontWeight: '700' }}
            text="Our Tiered Ranking System for Learning Difficulty:"
          />
          {[
            'Beginner-Friendly',
            'Straightforward',
            'Moderately Straightforward',
            'Challenging',
            'Advanced Challenge',
          ].map((item, ind) => (
            <DescriptionItems key={ind} text={item} />
          ))}
          <Description text="The key takeaway? None of the languages on this list are prohibitively difficult to learn. Still, some do come with steeper learning curves than others, even if only marginally." />
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '47.8294%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesFourthSection}
            />
          )}
          <Description sx={{ fontWeight: '700' }} text="The Results:" />
          {[
            ['Beginner-Friendly: ', 'SQL'],
            ['Straightforward: ', 'Python, JavaScript, Ruby, PHP'],
            ['Moderately Straightforward: ', 'TypeScript, Rust, Golang'],
            ['Challenging: ', 'Java, Kotlin, C#'],
            ['Advanced Challenge: ', 'C++'],
          ].map((item, ind) => (
            <Box
              key={ind}
              sx={{
                mb: { xs: '8px', md: '8px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  gap: '24px',
                }}
              >
                <span
                  style={{
                    color: theme.palette.orange.main,
                    fontSize: '40px',
                    lineHeight: '14px',
                    marginBottom: '5px',
                  }}
                >
                  •
                </span>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: { xs: 400, md: 500 },
                    lineHeight: { xs: '21px', md: '24px' },
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {item[0]}
                  <span style={{ fontWeight: 700 }}>{item[1]}</span>
                </Typography>
              </Box>
            </Box>
          ))}
          <Description text="SQL emerges as the simplest language to learn, with its straightforward syntax that can be picked up quickly. If your goal is to dive into data querying and manipulation, SQL is a great starting point." />
          <Description text="When it comes to programming languages with broader use cases, especially in web development:" />
          <Box
            sx={{
              mb: { xs: '16px', md: '16px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: '24px',
              }}
            >
              <span
                style={{
                  color: theme.palette.orange.main,
                  fontSize: '40px',
                  lineHeight: '14px',
                  marginBottom: '5px',
                }}
              >
                •
              </span>
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: { xs: 400, md: 500 },
                  lineHeight: { xs: '21px', md: '24px' },
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                }}
              >
                <span style={{ fontWeight: 700 }}> High-level languages </span>
                like
                <span style={{ fontWeight: 700 }}> Python </span>
                and
                <span style={{ fontWeight: 700 }}> JavaScript</span>
                are generally rated as straightforward to moderately straightforward in difficulty.
                These languages feature user-friendly syntax and a strong community backing, making
                the learning process smoother.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mb: { xs: '16px', md: '16px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: '24px',
              }}
            >
              <span
                style={{
                  color: theme.palette.orange.main,
                  fontSize: '40px',
                  lineHeight: '14px',
                  marginBottom: '5px',
                }}
              >
                •
              </span>
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: { xs: 400, md: 500 },
                  lineHeight: { xs: '21px', md: '24px' },
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                }}
              >
                On the other hand,
                <span style={{ fontWeight: 700 }}> low-level languages </span>
                such as
                <span style={{ fontWeight: 700 }}> C++ </span>
                are viewed as more of an advanced challenge due to their complex structures and
                lower abstraction from machine code.
              </Typography>
            </Box>
          </Box>
          <DescriptionWithChildren>
            For those who already have a foundation in programming,
            <span style={{ fontWeight: 700 }}> Rust, Golang </span> and{' '}
            <span style={{ fontWeight: 700 }}> TypeScript </span>
            and are often described as moderately straightforward to pick up.
            <span style={{ fontWeight: 700 }}> Java </span> benefits from an extensive ecosystem of
            learning resources, while <span style={{ fontWeight: 700 }}> Kotlin </span>
            and <span style={{ fontWeight: 700 }}> C# </span> have similarities to Java that make
            them accessible to those familiar with it.{' '}
          </DescriptionWithChildren>
          <DescriptionWithChildren>
            <span style={{ fontWeight: 700 }}>C++ </span> ranks as the most challenging language on
            this list — not because it’s insurmountably difficult, but because it demands more from
            the learner compared to others. If C++ is your chosen language, don’t be deterred. It
            may be more complex, but with dedication, it’s certainly achievable.
          </DescriptionWithChildren>
          <Criteria text="Criteria 4: Do Developers Love or Hate This Language?" />
          <DescriptionWithChildren>
            It’s one thing for a language to be easy to learn and come with a lucrative paycheck,
            but if using it day after day makes you want to pull your hair out, that’s a serious
            drawback.
            <br />
            To gauge this, we looked into how developers feel about working with these languages. Do
            they enjoy using them enough to want to continue, or do they avoid them like the plague?
            <br />
            Fortunately, the annual{' '}
            <span style={{ fontWeight: 700 }}> StackOverflow Developer Survey </span>
            {`is an invaluable resource that tracks how developers feel about programming languages and tools. Their 2023 survey introduced a new system called 'admired and desired,' which is a nuanced take on developer preferences.`}
          </DescriptionWithChildren>
          <Description sx={{ fontWeight: 700 }} text="What Do All Those Dots and Lines Mean?" />
          <Description
            sx={{ mb: '8px' }}
            text="The survey’s “admired and desired” results are a bit more intricate than the previous “most loved and hated” rankings. Here’s a quick breakdown:"
          />{' '}
          {[
            [
              'Blue dots on the left: ',
              ' Indicate the percentage of developers who have never used the language but are interested in trying it. The further to the right this dot is, the more people are eager to learn the language.',
            ],
            [
              'Red dots on the right: ',
              'Represent the percentage of developers currently using the language and who want to keep using it next year. The further along this dot is, the more developers enjoy working with the language.',
            ],
            [
              'The line length between the dots: ',
              ' Measures how well the language meets the expectations set by its reputation.',
            ],
          ].map((item, ind) => (
            <DescriptionItemsBoldRow key={ind} text={item[1]} textB={item[0]} />
          ))}
          <Description text="For our purposes, we focused on the red dots — the metric that answers the crucial question: Are developers who use a language enjoying it enough to continue?" />
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '90,542%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesFifhSection}
            />
          )}
          <Description sx={{ fontWeight: 700, mt: '16px' }} text="Does That Make Sense?" />
          <Description text="Great! Just remember, personal opinions are at play here, so take these findings with a grain of salt. Developers may be new to a language and dislike it due to inexperience, or they could be veterans who have grown weary of a language after years of use. And yes, in some cases, the language might truly be problematic. With such a broad range of responses, trends emerge but aren’t definitive for everyone." />
          <Description
            sx={{ fontWeight: 700 }}
            text="So, Which Languages Are Most Loved by Developers?"
          />
          <DescriptionAboveImg
            textFirst="2024 Programming Language Breakdown"
            textSecond="% of Developers who currently use the language and want to continue using it"
          />
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '47.8294%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesSixthSection}
            />
          )}
          <DescriptionWithChildren>
            <>
              When we filter down StackOverflow’s responses to just the languages on our list,
              <span style={{ fontWeight: 700 }}> Rust </span>
              emerges as the undisputed champion, followed by
              <span style={{ fontWeight: 700 }}> TypeScript </span>
              and
              <span style={{ fontWeight: 700 }}> Python. </span>
            </>
          </DescriptionWithChildren>
          <DescriptionWithChildren>
            <>
              Over
              <span style={{ fontWeight: 700 }}> 84,66 % of developers </span>
              currently using
              <span style={{ fontWeight: 700 }}> Rust </span>
              expressed that they love it and plan to keep using it. This level of satisfaction
              highlights why Rust consistently tops the charts as one of the most enjoyable
              languages to work with.
            </>
          </DescriptionWithChildren>
          <DescriptionWithChildren>
            <>
              <span style={{ fontWeight: 700 }}> TypeScript </span>
              and
              <span style={{ fontWeight: 700 }}> Python </span>
              follow closely behind, showcasing their blend of modern features, strong community
              support, and overall developer happiness.
            </>
          </DescriptionWithChildren>
          <Criteria text="Criteria 5: Future-Proof Outlook" />
          <Description
            sx={{ mb: '0px' }}
            text="Finally, we evaluated each language for its potential over the next 5-10 years. How future-proof is it? While we can’t make guarantees (so don’t hold us to it!), we can highlight trends and insights that suggest which languages are worth your time in the long term."
          />
          <DescriptionWithChildren>
            <>
              Our analysis leveraged data from the
              <span style={{ fontWeight: 700 }}> TIOBE index </span> — a reliable indicator of programming language popularity — and GitHub’s,
              <span style={{ fontWeight: 700 }}> 2023 pull request statistics </span>
              both of which track trends in usage and developer engagement.
            </>
          </DescriptionWithChildren>
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '86,3565%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesSeventhSection}
            />
          )}
          <Description sx={{ fontWeight: 700, mb: '8px' }} text="What We Found:" />
          <Description text="Great! Just remember, personal opinions are at play here, so take these findings with a grain of salt. Developers may be new to a language and dislike it due to inexperience, or they could be veterans who have grown weary of a language after years of use. And yes, in some cases, the language might truly be problematic. With such a broad range of responses, trends emerge but aren’t definitive for everyone." />
          {[
            [
              'Python: ',
              'Python continues to dominate the TIOBE index, showing substantial growth (+7.08%) and maintaining its position as the most popular language. Its versatility in AI, machine learning, data analysis, and web development ensures that Python remains highly relevant. While not the fastest language, its simplicity and extensive libraries make it indispensable.',
            ],
            [
              'JavaScript/TypeScript: ',
              'JavaScript is still the go-to for web development, while TypeScript, which enhances JavaScript with static typing, is steadily rising in popularity. Both languages score well on TIOBE and GitHub due to their robust ecosystems and wide adoption, making them safe bets for future growth.',
            ],
            [
              'Java: ',
              'This stalwart of enterprise software, Android development, and backend systems remains strong, with a +1.59% increase in TIOBE rankings and high placement in GitHub pull requests. Java’s proven reliability and continuous evolution solidify its future-proof status.',
            ],
            [
              'C++:',
              'With its leap to second place in the TIOBE index (+0.93%), C++ continues to be essential for systems programming, game development, and performance-critical applications. Its relevance is bolstered by new standards that modernize the language.',
            ],
            [
              'Go (Golang):',
              'With a +0.65% rise in the TIOBE index and significant activity on GitHub (+0.566%), Go is carving out its niche for networked and distributed systems. Its simplicity, performance, and concurrency support position it as a language with growing future potential.',
            ],
            [
              'Rust:',
              'Rust’s focus on memory safety and performance has driven its popularity, reflected in a +0.53% rise in the TIOBE index. Known for producing reliable, safe code, Rust is increasingly being adopted for systems programming and applications that prioritize security and efficiency.',
            ],
            [
              'C#:',
              "Although there’s a slight dip in the TIOBE index (-2.09%), C# remains integral for .NET development, game development with Unity, and enterprise applications. Its established use cases and Microsoft's ongoing support keep it relevant.",
            ],
            [
              'SQL:',
              'SQL is irreplaceable for database management and data-driven projects. While not seeing dramatic shifts in the TIOBE index, its omnipresence in data operations ensures its importance well into the future.',
            ],
          ].map((item, ind) => (
            <DescriptionItemsBold key={ind} text={item[1]} textB={item[0]} />
          ))}
          <Description sx={{ fontWeight: 700 }} text="Key Takeaways:" />
          {[
            ['Top Future-Proof Languages: ', 'Python, JavaScript/TypeScript, Java'],
            ['Solid Contenders: ', 'C++, Go, Rust, C#, SQL'],
            [
              'Worth Watching: ',
              'Kotlin, which is still gaining traction, and Ruby, which remains steady.',
            ],
            [
              'Use with Caution: ',
              'PHP, which has seen declining popularity, but still underpins many legacy systems.',
            ],
          ].map((item, ind) => (
            <DescriptionItemsBoldRow key={ind} text={item[1]} textB={item[0]} />
          ))}
          {!shouldRenderImage ? (
            <Skeleton
              animation="pulse"
              variant="rectangular"
              sx={{
                maxWidth: '1290px',
                width: '100%',
                height: 0,
                paddingBottom: '38,837%',
                backgroundColor: 'rgba(0,0,0,0.44)',
                borderRadius: '20px',
              }}
            />
          ) : (
            <Box
              sx={{ maxWidth: '1290px', width: '100%', height: 'auto', borderRadius: '20px'  }}
              component="img"
              src={ProgrammingLanguagesEighthSection}
            />
          )}
          <Description sx={{ fontWeight: 700, my: '8px' }} text="Summary:" />
          <DescriptionWithChildren sx={{ mb: '8px' }}>
            Python, JavaScript/TypeScript, and Java stand out as the most future-proof options, with
            strong growth and robust ecosystems. Emerging languages like
            <span style={{ fontWeight: 700 }}> Go </span>
            and
            <span style={{ fontWeight: 700 }}> Rust </span>
            are also worth considering, thanks to their alignment with modern needs for speed,
            security, and efficiency.
          </DescriptionWithChildren>
          <Description text="Ultimately, all these languages have distinct strengths and specialized areas of use. Your choice should align with your career goals and interests, but from a forward-looking perspective, investing time in languages that demonstrate consistent growth and industry alignment is a strategic move." />
          <Description
            sx={{
              fontSize: { xs: '16px', md: '20px' },
              fontWeight: 700,
              lineHeight: { xs: '24px', md: '30px' },
              mb: '16px',
            }}
            text="Here Are Our Language Winners for Some of the Most In-Demand Careers"
          />
          {[
            [
              'Best for Web Development:',
              'JavaScript',
              'This was a tough choice, as TypeScript and Rust scored higher in some criteria. Still, JavaScript’s established ecosystem, frameworks, and widespread use made it our top pick.',
            ],
            [
              'Best for Machine Learning & AI (and All Things Data):',
              'Python',
              "The clear choice here. If you're serious about breaking into machine learning, consider supplementing your skills with a Machine Learning Bootcamp.",
            ],
            [
              'Best for Game Development:',
              'C++ (for Unreal Engine); C# (for Unity)',
              'Both languages excel in game development, offering robust support and a solid foundation for building complex, immersive games.',
            ],
            [
              'Best for VR / AR:',
              'C++ or C#',
              'The same pair that dominates game development leads in the VR/AR space, thanks to their efficiency and extensive library support.',
            ],
            [
              'Best for Mobile App Development:',
              'Java and Kotlin (for Android); JavaScript + React Native',
              'For Android development, Java and Kotlin are top choices. If cross-platform development is your goal, JavaScript with React Native is a powerful alternative',
            ],
            [
              'Best for Desktop Applications:',
              'Java or C++',
              'Both languages have a history of reliability and performance in building crossplatform desktop software.',
            ],
            [
              'Best for Systems Programming:',
              'Rust or Go',
              'For performance-oriented and secure systems programming, Rust is a standout choice. Go offers simplicity and efficiency, making it excellent for networked and distributed systems.',
            ],
          ].map((item, ind) => (
            <Box
              key={ind}
              sx={{
                mb: '16px',
              }}
            >
              <Description
                sx={{
                  fontWeight: 700,
                  mb: '8px',
                }}
                text={item[0]}
              />
              <DescriptionItemsBold textB={item[1]} text={item[2]} />
            </Box>
          ))}
          <Description
            sx={{
              fontSize: { xs: '14px', md: '20px' },
              fontWeight: { xs: 400, md: 700 },
              lineHeight: { xs: '21px', md: '30px' },
              mt: '32px',
            }}
            text="The Top 12 Programming Languages for 2025 based on all criterias:"
          />
          {[
            [
              '1. Python',
              'The undisputed champion with versatility, ease of learning, and a commanding presence across industries like AI, data science, and web development.',
            ],
            [
              '2. SQL',
              'Surprised? We were too. But SQL’s essential role in data handling and its simplicity make it a vital skill for the data-driven world.',
            ],
            [
              '3. TypeScript',
              'An increasingly popular choice that builds on JavaScript’s ubiquity with added type safety and enhanced developer experience.',
            ],
            [
              '4. Rust',
              'Loved by developers for its focus on safety and performance, Rust is becoming a go-to for secure systems programming and next-gen applications.',
            ],
            [
              '5. JavaScript',
              'The backbone of modern web development, continuously evolving with frameworks and libraries that keep it relevant.',
            ],
            [
              '6. C++',
              'A powerful language with staying power, essential for performance-critical applications and game development.',
            ],
            [
              '7. Java',
              'The undisputed champion with versatility, ease of learning, and a commanding presence across industries like AI, data science, and web development.',
            ],
            [
              '8. Golang (Go)',
              'Simple, efficient, and gaining traction for networked and distributed systems, making it an excellent choice for backend development.',
            ],
            [
              '9. C#',
              'Solid for .NET applications, game development with Unity, and enterprise software, supported by Microsoft’s continual updates.',
            ],
            [
              '10. Ruby',
              'The undisputed champion with versatility, ease of learning, and a commanding presence across industries like AI, data science, and web development.',
            ],
            [
              '11. Kotlin',
              'Popular for Android development, Kotlin offers concise syntax and seamless interoperability with Java, securing its place on this list.',
            ],
            [
              '12. PHP',
              'Surprised to see PHP? Despite its declining trend, PHP still powers a significant portion of the web and is often underestimated. Its presence here acknowledges its widespread legacy use and practical applications.',
            ],
          ].map((item, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                mb: { xs: '16px', md: '8px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '16px' },
                  fontWeight: 700,
                  lineHeight: { xs: '24px', md: '16px' },
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                  textWrap: 'nowrap',
                }}
              >
                {item[0]}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: { xs: 400, md: 500 },
                  lineHeight: { xs: '21px', md: '24px' },
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                }}
              >
                {item[1]}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              mt: '16px',
              // width: '100%',
              borderBottom: '1px solid #B1B1B1',
            }}
          />
          {languageBottomSection.map((item, ind) => (
            <Box
              key={ind}
              sx={{
                py: '16px',
                boxSizing: 'border-box',
                borderBottom: '1px solid #B1B1B1',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                src={item.img.src}
                sx={{ position: 'absolute', ...item.img.imgSx }}
              />
              <Description
                sx={{
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '30px',
                  mb: { xs: '16px', md: '16px' },
                }}
                text={item.title}
              />
              <DescriptionWithChildren sx={{ mb: { xs: '8px', md: '8px' } }}>
                <span style={{ fontWeight: 700 }}>Primary Uses: </span>
                {item.primaryUses}
              </DescriptionWithChildren>
              <DescriptionWithChildren sx={{ mb: { xs: '8px', md: '8px' } }}>
                <span style={{ fontWeight: 700 }}>Adjusted Job Postings: </span>
                {item.adjustedJobPostings}
              </DescriptionWithChildren>
              <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' } }}>
                <span style={{ fontWeight: 700 }}>Average Salary: </span>
                {item.averageSalary}
              </DescriptionWithChildren>
              {!!item?.useWithCaution && (
                <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' } }}>
                  <span style={{ fontWeight: 700 }}>Use with Caution: </span>
                  {item?.useWithCaution}
                </DescriptionWithChildren>
              )}
              <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' }, fontWeight: 700 }}>
                <span style={{ fontWeight: 700 }}>Learning Difficulty: </span>
                {item.learningDifficulty}
              </DescriptionWithChildren>
              <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' }, fontWeight: 700 }}>
                <span style={{ fontWeight: 700 }}>Developer Sentiment: </span>
                {item.developerSentiment}
              </DescriptionWithChildren>
              <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' } }}>
                <span style={{ fontWeight: 700 }}>Future Outlook:</span>
                {item.futureOutlook}
              </DescriptionWithChildren>
              <DescriptionWithChildren sx={{ mb: { xs: '16px', md: '16px' } }}>
                <span style={{ fontWeight: 700 }}>Description:</span>
                <br />
                {item.description.map((item, ind) => (
                  <span key={ind}>
                    {item}
                    <br />
                  </span>
                ))}
              </DescriptionWithChildren>
              {!!item?.careerOpportunities && (
                <>
                  <Description
                    sx={{
                      fontWeight: 700,
                    }}
                    text={'Career Opportunities:'}
                  />
                  {item.careerOpportunities.map((item, ind) => (
                    <Box key={ind}>
                      {item?.title && <Description text={item.title} />}
                      {item.items.map((descrItem, descrItemInd) => (
                        <DescriptionItemsBoldRow
                          key={descrItemInd}
                          textB={descrItem[0]}
                          text={descrItem[1]}
                        />
                      ))}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          ))}
          <Description
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '30px',
              mt: { xs: '32px', md: '32px' },
            }}
            text="Final Conclusion"
          />
          <Description
            sx={{
              fontWeight: 700,
              mb: '8px',
            }}
            text="If You’re Not Sure Where to Start:"
          />
          {[
            [
              'Python',
              'should be at the top of your list. It’s simple, versatile, and consistently in high demand. Whether you’re interested in web development, data science, or automation, Python provides a robust starting point that can lead you to a range of career paths.',
            ],
            [
              'JavaScript:',
              'is non-negotiable if web development is your goal. As the backbone of interactive websites and applications, mastering JavaScript opens the door to both front-end and full-stack development.',
            ],
          ].map((item, ind) => (
            <Box key={ind}>
              <DescriptionItemsBold textB={item[0]} text={item[1]} />
            </Box>
          ))}
          <Description
            sx={{
              fontWeight: 700,
              mb: '8px',
            }}
            text="Important Tips for Your Learning Journey:"
          />
          {[
            [
              'Determine Your Goals:',
              'Think critically about what area of programming excites you most. Are you drawn to building apps, analyzing data, or creating immersive game experiences? Your goals will shape your learning path.',
            ],
            [
              'Start with One Language:',
              'Mastering one language will make it significantly easier to pick up others. Lay a strong foundation before branching out.',
            ],
            [
              'Practice Regularly:',
              'Programming is a skill that thrives on consistency. Regular practice will reinforce your learning and improve your problem-solving abilities.',
            ],
            [
              'Stay Updated:',
              ' Technology evolves at a breakneck pace. Keep an eye on new tools, languages, and frameworks that could enhance your skill set or introduce you to exciting new possibilities.',
            ],
          ].map((item, ind) => (
            <Box key={ind}>
              <DescriptionItemsBold textB={item[0]} text={item[1]} />
            </Box>
          ))}
          <DescriptionWithChildren>
            <span style={{ fontWeight: 700 }}> Most importantly</span>
            {`, overcome laziness and procrastination. From my experience, discipline and countless attempts will be what ultimately make you successful. Push through the moments of doubt and keep going — you'll be amazed at how far you can get with persistent effort.`}
          </DescriptionWithChildren>
          <Description sx={{ fontWeight: 700, mb: '4px' }} text="Final Thought:" />
          <DescriptionWithChildren>
            The best language to learn is the one that aligns with your interests and career
            aspirations. Start small, tackle achievable projects, and build from there. With
            dedication and curiosity, you’ll find yourself navigating the programming world with
            confidence and adaptability.
          </DescriptionWithChildren>
          <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap',mt:'38px' }}>
            {pageTags.map((tag) => (
              <Typography
                key={tag}
                sx={{
                  maxWidth: { xs: '45vw', md: 'auto' },
                  width: { xs: '100%', md: 'auto' },
                  minWidth: { xs: '200px', md: 'auto' },
                  fontSize: { xs: '14px', md: '16px' },
                  fontWeight: 500,
                  lineHeight: { xs: '21px', md: '24px' },
                  textAlign: { xs: 'left', md: 'center' },
                  border: '1px solid #B0B5B7',
                  borderRadius: '73px',
                  boxSizing: 'border-box',
                  p: { xs: '8px 16px', md: '8px 14px' },
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.palette.text.primary,
                  textWrap: 'nowrap',
                }}
              >
                {tag}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Languages;

import { Terms } from 'components/common/TermsTemplate';

export const privacyPolicy: Terms = {
  title: 'Privacy Policy for We Can Develop IT LLC',
  createdAt: 'Last updated: 10 Jan 2024',
  description:
    'At We Can Develop IT LLC (“we,” “us,” or “our”), we are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this policy carefully to understand our views and practices regarding your personal data.',
  content: [
    { title: '1. Information We Collect' },
    {
      description: [
        'We collect the following types of personal information from you when you visit our website or use our services:',
      ],
    },
    {
      descriptionItems: [
        'Email address',
        'Full name',
        'Cell phone number',
        'Company name',
        'Country or City',
      ],
    },
    {
      description: [
        'We collect this information when you fill out forms, request services, or contact us for inquiries. In addition, we use cookies and similar tracking technologies to collect information about your use of our website. This may include:',
      ],
    },
    {
      descriptionItems: ['IP address', 'Device information', 'Browsing behavior'],
    },
    { title: '2. How We Use Your Information' },
    {
      description: ['We process the personal information we collect for the following purposes:'],
    },
    {
      descriptionItems: ['Processing Orders:'],
    },
    {
      descriptionItemsWithoutDot: [
        'We use your data to handle and fulfill your orders for services or products.',
      ],
    },
    {
      descriptionItems: ['Marketing:'],
    },
    {
      descriptionItemsWithoutDot: [
        'We may use your data to send you promotional materials or newsletters, but only if you have opted in to receive these communications.',
      ],
    },
    {
      descriptionItems: ['Improving User Experience:'],
    },
    {
      descriptionItemsWithoutDot: [
        'We analyze how users interact with our website to improve user experience and website functionality.',
      ],
    },
    {
      descriptionItems: ['Personalizing Content:'],
    },
    {
      descriptionItemsWithoutDot: [
        'We use cookies to personalize content and ads based on your preferences and behavior.',
      ],
    },
    {
      descriptionItems: ['Analyzing Website Traffic:'],
    },
    {
      descriptionItemsWithoutDot: [
        'Cookies help us track website traffic and analyze user behavior to improve our services.',
      ],
    },
    { title: '3. Cookies and Data Sharing' },
    {
      description: ['We use cookies to:'],
    },
    {
      descriptionItems: [
        'Personalize content and ads',
        'Provide social media features',
        'Analyze our website traffic',
      ],
    },
    {
      description: [
        'We also share information about your use of our site with our social media, advertising, and analytics partners, such as Google Analytics, to enhance your experience and provide relevant advertisements.',
        'If you prefer to disable cookies, you can do so through your browser settings. However, please note that disabling cookies may affect the functionality of our website.',
        'For more information about how Google uses data, please review their Privacy Policy',
      ],
    },
    { title: '4. Consent and User Rights' },
    {
      description: [
        'We do not share your personal information with third parties for their direct marketing purposes. However, we do share information about your use of our website with:',
      ],
    },
    {
      descriptionItems: ['Access:'],
    },
    {
      descriptionItemsWithoutDot: [
        'You can request access to the personal data we hold about you.',
      ],
    },
    {
      descriptionItems: ['Correction:'],
    },
    {
      descriptionItemsWithoutDot: [
        'If any information is incorrect, you have the right to ask for it to be corrected.',
      ],
    },
    {
      descriptionItems: ['Deletion (Right to be Forgotten):'],
    },
    {
      descriptionItemsWithoutDot: [
        "You can ask us to delete your personal data if it's no longer necessary for the purposes it was collected.",
      ],
    },
    {
      descriptionItems: ['Objection:'],
    },
    {
      descriptionItemsWithoutDot: [
        'You may object to certain types of data processing, such as marketing.',
      ],
    },
    {
      description: ['To exercise any of these rights, please contact us at '],
    },
    {
      link: {
        title: 'support@wecandevelopit.com.',
        link: '',
      },
    },
    { title: '5. Sharing Your Information' },
    {
      description: [
        'We do not share your personal information with third parties for their direct marketing purposes. However, we do share information about your use of our website with:',
      ],
    },
    {
      descriptionItems: ['Social Media Partners:'],
    },
    {
      descriptionItemsWithoutDot: ['To enable social sharing and improve your user experience.'],
    },
    {
      descriptionItems: ['Advertising Partners:'],
    },
    {
      descriptionItemsWithoutDot: [
        'To provide personalized advertisements based on your interests.',
      ],
    },
    {
      descriptionItems: ['Analytics Partners:'],
    },
    {
      descriptionItemsWithoutDot: [
        'To analyze website traffic and user behavior (e.g., Google Analytics).',
      ],
    },
    {
      description: [
        'These third parties may also use cookies to collect information about your interaction with our website.',
      ],
    },
    { title: '6. Data Security' },
    {
      description: [
        'We take the security of your personal information seriously and have implemented measures to protect it. However, no online service is completely secure, and we cannot guarantee absolute security. We recommend that you use secure and trusted networks to access our services.',
      ],
    },
    { title: '7. Changes to This Privacy Policy' },
    {
      description: [
        'We may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. If we make any significant changes, we will notify you via email (if you’ve provided one) or by posting a notice on our website.',
      ],
    },
    { title: '8. International Data Transfers' },
    {
      description: [
        'If you are located outside the United States, please be aware that your information will be transferred to and processed in the United States, where our servers and offices are located. We ensure that such data transfers comply with applicable data protection laws, including GDPR, by using appropriate safeguards.',
      ],
    },
    { title: '9. Contact Us' },
    {
      description: [
        'If you have any questions about this Privacy Policy or how we handle your personal data, please contact us at:',
      ],
    },
    {
      descriptionItems: [
        'We Can Develop IT LLC',
        '12000 Ancient Crest Circle, Apt 12107',
        'Franklin, Tennessee, 37067',
        { item: 'Email:', linkTitle: 'business@wecandevelopit.com', link: '' },
      ],
    },
    {
      externalLinks:{
        title: 'Design by FREEPIK ',
        links:[
          {
            title:'(Video 1; ',
            link:'https://www.freepik.com/free-video/american-businessman-showing-something-computer-his-two-female-colleagues-discussing-together-office-1_175680#fromView=resource_detail&position=3&from_element=related_resources'
          },
          {
            title:'Video 2; ',
            link:'https://www.freepik.com/free-video/business-men-woman-walking-towards-camera-corridor-people-go-work-meeting_2489491#fromView=search&page=1&position=43&uuid=ad948ae3-6013-4f24-bfd1-5c08163069ad'
          },
          {
            title:'Video 3)',
            link:'https://www.freepik.com/free-video/creative-team-sitting-standing-table-with-laptop_478842#fromView=resource_detail&position=1&from_element=related_resources'
          },
        ]
      }
    }
  ],
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import NewsCard from 'components/main/news/NewsCard';
import NewsCarousel from 'components/main/news/NewsCarousel';

import { news } from 'pages/main/news/consts';

import { ReactComponent as Blog } from 'assets/main/news/Blog.svg';
import { ReactComponent as News } from 'assets/main/news/News.svg';
import { ReactComponent as Pencil } from 'assets/main/news/Pencil.svg';

import DreamSection from '../dream';

const NewsSection = () => {
  const navigate = useNavigate();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();

  return (
    <Box
      id="news"
      className="news_section"
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        id="news"
        sx={{
          width: '100%',
          position: 'relative',
          p: { xs: '100px 16px 50px 16px', md: '140px 24px 70px 24px' },
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            maxWidth: '1920px',
            width: '100%',
            margin: 'auto',
            minHeight: { xs: '316px', md: '995px' },
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              maxWidth: '1750px',
              width: '100%',
              top: '140px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10,
            }}
          >
            <FlyingBall
              clsName="news_section_flying_ball"
              setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
              sx={{ right: '-20px', top: '-100px' }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '58px',
              left: '-190px',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Pencil />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: { xs: '7px', md: '17px' },
              right: '1px',
              pl: '60px',
            }}
          >
            <Blog
              style={{
                maxWidth: '848px',
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '114px',
              left: '8px',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <News
              style={{
                maxWidth: '439px',
                width: '100%',
                height: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              maxWidth: { sm: '900px', xl: '1290px' },
              width: '100%',
              margin: 'auto',
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: '36px', md: '64px' },
                fontWeight: 800,
                lineHeight: { xs: '54px', md: '96px' },
                textAlign: 'left',
                mb: { xs: '32px', md: '54px' },
                color: theme.palette.text.secondary,
              }}
            >
              Blog and news
            </Typography>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex', gap:'30px',justifyContent:'center',flexWrap:'wrap' },
              }}
            >
              {news.slice(0, 6).map((item) => (
                <Box
                  key={item.id}
                  className={`news_card_${item.id}`}
                >
                  <NewsCard news={item} />
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <NewsCarousel news={news} />
            </Box>

          </Box>
        </Box>
        <CustomButton
          sx={{
            margin:'auto',
            mt:'32px',
            maxWidth: { xs: '500px', md: '158px' },
            width:'100%',
            height: '56px',
            borderRadius: '30px',
            backgroundColor: theme.palette.black.main,
            color: theme.palette.text.primary,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
          }}
          onClick={() => navigate('/news')}
        >
          View all news
        </CustomButton>
      </Box>
      <DreamSection />
    </Box>
  );
};

export default NewsSection;

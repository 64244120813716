import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useState } from 'react';
import { theme } from 'theme';
import { boxHeight } from 'utils/boxHeight';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';
import NewsCard from 'components/main/news/NewsCard';

import { INews, news, tags } from 'pages/main/news/consts';

import { ReactComponent as Blog } from 'assets/main/news/Blog.svg';
import { ReactComponent as News } from 'assets/main/news/News.svg';
import { ReactComponent as Pencil } from 'assets/main/news/Pencil.svg';

import { newsPageAnimation } from './animation';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const NewsPage = () => {
  const [pageTags, setPageTags] = useState<string[]>(['All']);
  const [sortedNews, setSortedNews] = useState<INews[]>(news);
  const [newsPortion, setNewsPortion] = useState<number>(2);
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  useEffect(() => {
    const sorted = news.filter((news) =>
      news.pageTags?.some((tag) => {
        if (pageTags.includes('All')) {
          return true;
        } else {
          return pageTags.includes(tag);
        }
      })
    );
    setSortedNews(sorted);
  }, [pageTags]);
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1025px)', () => {
      newsPageAnimation(boxHeight('.news_page_content'));
    });
  }, [boxHeight('.news_page_content'), newsPortion]);
  return (
    <Box
      id="news"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: { xs: '0px', md: '0px' },
          p: { xs: '100px 16px', md: '140px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <FlyingBall
          clsName="news_page_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          sx={{ right: '-20px', top: '200px' }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '58px',
            left: '-190px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Pencil />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '49px', md: '17px' },
            right: '1px',
            pl: '60px',
          }}
        >
          <Blog
            style={{
              maxWidth: '848px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '114px',
            left: '8px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <News
            style={{
              maxWidth: '439px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          className="news_page_content"
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '36px', md: '64px' },
              fontWeight: 800,
              lineHeight: { xs: '54px', md: '96px' },
              textAlign: 'left',
              mb: { xs: '32px', md: '54px' },
              color: theme.palette.text.secondary,
            }}
          >
            Blog and news
          </Typography>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: '8px' }}>
            {tags.map((tag) => (
              <Typography
                key={tag.id}
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '21px',
                  textAlign: 'left',
                  border: '1px solid #B0B5B7',
                  borderRadius: '73px',
                  boxSizing: 'border-box',
                  p: '6px 12px',
                  color: pageTags.includes(tag.title)
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                  backgroundColor: pageTags.includes(tag.title)
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                  mb: '32px',
                  mt: '-16px',
                }}
                onClick={() => {
                  if (pageTags.includes(tag.title)) {
                    setPageTags((prev) => prev.filter((item) => item !== tag.title));
                  } else {
                    setPageTags((prev) => [...prev, tag.title]);
                  }
                }}
              >
                {tag.title}
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              justifyContent: { xs: 'space-around', md: 'space-between' },
              flexWrap: 'wrap',
            }}
          >
            {newsPortion >= 1 &&
              sortedNews.slice(0, 6).map((item) => (
                <Box key={item.id} className={`news_card_${item.id}`}>
                  <NewsCard news={item} />
                </Box>
              ))}
            {newsPortion > 1 &&
              sortedNews.slice(6).map((item) => (
                <Box key={item.id} className={`news_card_${item.id}`}>
                  <NewsCard news={item} />
                </Box>
              ))}
          </Box>
        </Box>
        {!!sortedNews.length && newsPortion === 1 && (
          <CustomButton
            sx={{
              margin: 'auto',
              mt: '32px',
              maxWidth: { xs: '500px', md: '158px' },
              width: '100%',
              height: '56px',
              borderRadius: '30px',
              backgroundColor: theme.palette.black.main,
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
            onClick={() => {
              setNewsPortion((prev) => prev + 1);
            }}
          >
            Load more
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default NewsPage;

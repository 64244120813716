import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'theme';
import { boxHeight } from 'utils/boxHeight';
import { preloadImages } from 'utils/preloadingImages';

import { Box, Link as MuiLink, Skeleton, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { useGotAProject } from 'components/common/GotAProject/GotAProjectContext';

import { news } from 'pages/main/news/consts';

import { ReactComponent as Blog } from 'assets/main/news/Blog.svg';
import { ReactComponent as News } from 'assets/main/news/News.svg';
import { ReactComponent as BackArrow } from 'assets/news/BackArrow.svg';

import { newsDetailsPageAnimation } from '../animation';
import Languages from './languages';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);
const NewsDetails = () => {
  const navigate = useNavigate();
  const { setIsOpenGotAProjectDrawer } = useGotAProject();
  const { id } = useParams();
  const currentNews = id ? news.find((item) => item.id === +id) : undefined;
  const [shouldRenderImage, setShouldRenderImage] = useState(false);
  useGSAP(() => {
    let mm = gsap.matchMedia();
    mm.add('(min-width: 1025px)', () => {
      newsDetailsPageAnimation(boxHeight('.news_details_page_content'));
    });
  }, [boxHeight('.news_details_page_content')]);
  useEffect(() => {
    (async () => {
      await preloadImages(
        currentNews?.page
          ?.filter((item) => Object.keys(item).includes('pageImage'))
          .reduce((acc: string[], item) => {
            if (typeof item?.pageImage?.image === 'string') {
              acc.push(item.pageImage.image);
              return acc;
            } else {
              return acc;
            }
          }, []) || []
      );
      setShouldRenderImage(true);
    })();
  });
  if (id === '6') {
    return <Languages />;
  }
  return (
    <Box
      id="news"
      className="news_details"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '1293px',
          p: { xs: '100px 16px', md: '140px 24px' },
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <FlyingBall
          clsName="news_details_page_flying_ball"
          setIsOpenGotAProjectDrawer={setIsOpenGotAProjectDrawer}
          bgClr={theme.palette.black.main}
          sx={{ right: '-20px', top: '200px' }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '49px', md: '17px' },
            right: '1px',
            pl: '60px',
          }}
        >
          <Blog
            style={{
              maxWidth: '848px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '114px',
            left: '8px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <News
            style={{
              maxWidth: '439px',
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
        <Box
          className="news_details_page_content"
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Box
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              mb: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              cursor: 'pointer',
              width: '70px',
            }}
          >
            <BackArrow />
            <Typography
              sx={{
                fontSize: { xs: '16px', md: '16px' },
                fontWeight: 500,
                lineHeight: { xs: '24px', md: '24px' },
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }}
            >
              Back
            </Typography>
          </Box>
          <Typography
            sx={{
              margin: 'auto',
              fontSize: { xs: '24px', md: '48px' },
              fontWeight: 800,
              lineHeight: { xs: '36px', md: '72px' },
              textAlign: 'center',
              mb: { xs: '32px', md: '54px' },
              maxWidth: '990px',
              color: theme.palette.text.secondary,
            }}
          >
            {currentNews?.title}
          </Typography>
          {currentNews?.page?.map((item, ind) => (
            <Box key={ind} sx={{ display: 'flex', flexDirection: 'column' }}>
              {(item?.pageImage || item.pageVideo) && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '32px' }}>
                  {item?.pageImage &&
                    (!shouldRenderImage ? (
                      <Skeleton
                        animation="pulse"
                        variant="rectangular"
                        sx={{
                          maxWidth: '1290px',
                          width: '100%',
                          height: 0,
                          paddingBottom: item?.pageImage?.skeletonHeight,
                          backgroundColor: 'rgba(0,0,0,0.44)',
                          borderRadius: '20px',
                        }}
                      />
                    ) : (
                      <Box
                        component="img"
                        src={item.pageImage.image}
                        sx={{ borderRadius: '20px' }}
                      />
                    ))}
                  {item?.pageVideo && (
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: '194px', sm: '400px', md: '600px', xl: '730px' },
                        margin: 'auto',
                      }}
                    >
                      <iframe
                        src={item.pageVideo}
                        style={{
                          width: '100%',
                          height: '100%',
                          margin: 'auto',
                          border: 'none',
                          borderRadius: '20px',
                        }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Box>
                  )}
                  {ind === 0 && (
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'right',
                        color: '#A3A3A3',
                        mt: '8px',
                      }}
                    >
                      {currentNews?.createdAt}
                    </Typography>
                  )}
                </Box>
              )}
              {item?.pageSubtitle && (
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '24px' },
                    fontWeight: 500,
                    lineHeight: { xs: '24px', md: '36px' },
                    textAlign: 'left',
                    mb: { xs: '16px', md: '32px' },
                    color: theme.palette.text.secondary,
                  }}
                >
                  {item.pageSubtitle}
                </Typography>
              )}
              {item?.pageDescription &&
                item?.pageDescription.map((item, ind) => (
                  <Typography
                    key={ind}
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      fontWeight: { xs: 400, md: 500 },
                      lineHeight: { xs: '21px', md: '24px' },
                      textAlign: 'left',
                      mb: { xs: '16px', md: '24px' },
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              {item?.orangeText && (
                <Typography
                  key={ind}
                  sx={{
                    fontSize: { xs: '16px', md: '24px' },
                    fontWeight: 500,
                    lineHeight: { xs: '24px', md: '36px' },
                    textAlign: 'left',
                    mb: { xs: '16px', md: '32px' },
                    color: theme.palette.orange.main,
                  }}
                >
                  {item.orangeText}
                </Typography>
              )}
              {item?.footnote && (
                <Typography
                  key={ind}
                  sx={{
                    fontSize: '14x',
                    fontWeight: 400,
                    lineHeight: '21px',
                    textAlign: 'left',
                    mb: { xs: '16px', md: '32px' },
                  }}
                >
                  <span
                    style={{
                      color: theme.palette.orange.main,
                    }}
                  >
                    *
                  </span>
                  {item.footnote}
                </Typography>
              )}
              {item?.pageDescriptionItems &&
                item?.pageDescriptionItems.map((item, ind) => (
                  <Box
                    key={ind}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '24px',
                      mb: { xs: '16px', md: '24px' },
                    }}
                  >
                    <span
                      style={{
                        color: theme.palette.orange.main,
                        fontSize: '40px',
                        lineHeight: '14px',
                        marginBottom: '5px',
                      }}
                    >
                      •
                    </span>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: { xs: 400, md: 500 },
                        lineHeight: { xs: '21px', md: '24px' },
                        textAlign: 'left',
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              {item?.pageDescriptionItemsBold &&
                item?.pageDescriptionItemsBold.map((item, ind) => (
                  <Box
                    key={ind}
                    sx={{
                      mb: { xs: '16px', md: '24px' },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '24px',
                      }}
                    >
                      <span
                        style={{
                          color: theme.palette.orange.main,
                          fontSize: '40px',
                          lineHeight: '14px',
                          marginBottom: '5px',
                        }}
                      >
                        •
                      </span>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: '16px', md: '20px' },
                            fontWeight: 700,
                            lineHeight: { xs: '24px', md: '30px' },
                            textAlign: 'left',
                            color: theme.palette.text.secondary,
                            textWrap: 'nowrap',
                          }}
                        >
                          {item[0]}
                        </Typography>
                        <Typography
                          sx={{
                            display: { xs: 'none', md: 'block' },
                            fontSize: { xs: '14px', md: '16px' },
                            fontWeight: { xs: 400, md: 500 },
                            lineHeight: { xs: '21px', md: '24px' },
                            textAlign: 'left',
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {item[1]}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        display: { xs: 'block', md: 'none' },
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: { xs: 400, md: 500 },
                        lineHeight: { xs: '21px', md: '24px' },
                        textAlign: 'left',
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {item[1]}
                    </Typography>
                  </Box>
                ))}
              {item?.pageDescriptionBold && (
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      fontWeight: { xs: 400, md: 500 },
                      lineHeight: { xs: '21px', md: '24px' },
                      textAlign: 'left',
                      color: theme.palette.text.secondary,
                      mb: { xs: '16px', md: '24px' }
                    }}
                  >
                    {item.pageDescriptionBold[0]}
                    <span
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {item.pageDescriptionBold[1]}
                    </span>
                    {item.pageDescriptionBold[2]}
                  </Typography>
              )}
              {item?.pageDescriptionItalic && (
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    fontWeight: { xs: 400, md: 500 },
                    lineHeight: { xs: '21px', md: '24px' },
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                    fontStyle:'italic',
                    mb: { xs: '16px', md: '24px' }
                  }}
                >
                  {item.pageDescriptionItalic}
                </Typography>
              )}
              {item?.pageDescriptionItemsWithoutDot &&
                item.pageDescriptionItemsWithoutDot.map((item, ind) => (
                  <Box
                    key={ind}
                    sx={{ display: 'flex', alignItems: 'center', gap: '30px', mb: '8px' }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', md: '16px' },
                        fontWeight: { xs: 400, md: 500 },
                        lineHeight: { xs: '21px', md: '24px' },
                        textAlign: 'left',
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              {currentNews?.page?.length && ind === currentNews?.page?.length - 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <MuiLink
                    href={currentNews?.pageUrl?.link}
                    underline="none"
                    target="_blank"
                    sx={{
                      mt: '8px',
                      fontSize: { xs: '16px', md: '24px' },
                      fontWeight: 500,
                      lineHeight: { xs: '24px', md: '36px' },
                      textAlign: 'left',
                      color: theme.palette.orange.main,
                      mb: { xs: '36px', md: '54px' },
                    }}
                  >
                    {currentNews?.pageUrl?.title}
                  </MuiLink>
                  <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                    {currentNews?.pageTags?.map((tag) => (
                      <Typography
                        key={tag}
                        sx={{
                          maxWidth: { xs: '45vw', md: 'auto' },
                          width: { xs: '100%', md: 'auto' },
                          minWidth: { xs: '200px', md: 'auto' },
                          fontSize: { xs: '14px', md: '16px' },
                          fontWeight: 500,
                          lineHeight: { xs: '21px', md: '24px' },
                          textAlign: { xs: 'left', md: 'center' },
                          border: '1px solid #B0B5B7',
                          borderRadius: '73px',
                          boxSizing: 'border-box',
                          p: { xs: '8px 16px', md: '8px 14px' },
                          color: theme.palette.text.secondary,
                          backgroundColor: theme.palette.text.primary,
                          textWrap: 'nowrap',
                        }}
                      >
                        {tag}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          ))}
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsDetails;

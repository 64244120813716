import { FunctionComponent, SVGProps } from 'react';
import { theme } from 'theme';

import { SxProps } from '@mui/material';

import BlockchainDevelopment from 'assets/main/services/BlockchainDevelopment.webp';
import BlockchainDevelopmentMV from 'assets/main/services/BlockchainDevelopmentMV.webp';
import BotsAndAI from 'assets/main/services/BotsAndAI.webp';
import CRM from 'assets/main/services/CRM.webp';
import ECommerce from 'assets/main/services/ECommerce.webp';
import ITSupportAndMaintenance from 'assets/main/services/ITSupportAndMaintenance.webp';
import MobileWebApplications from 'assets/main/services/MobileWebApplications.webp';
import MobileWebApplicationsMV from 'assets/main/services/MobileWebApplicationsMV.webp';
import { ReactComponent as PlusBlack } from 'assets/main/services/PlusBlack.svg';
import { ReactComponent as PlusOrange } from 'assets/main/services/PlusOrange.svg';
import { ReactComponent as PlusWhite } from 'assets/main/services/PlusWhite.svg';
import BlockchainDevelopmentPopup from 'assets/main/services/popup/BlockchainDevelopment/BlockchainDevelopment.webp';
import Chainlink from 'assets/main/services/popup/BlockchainDevelopment/Chainlink.png';
import Corda from 'assets/main/services/popup/BlockchainDevelopment/Corda.png';
import EOS from 'assets/main/services/popup/BlockchainDevelopment/EOS.png';
import Ethereum from 'assets/main/services/popup/BlockchainDevelopment/Ethereum.png';
import Hyperledger from 'assets/main/services/popup/BlockchainDevelopment/Hyperledger.png';
import Polkadot from 'assets/main/services/popup/BlockchainDevelopment/Polkadot.png';
import Solidity from 'assets/main/services/popup/BlockchainDevelopment/Solidity.png';
import Truffle from 'assets/main/services/popup/BlockchainDevelopment/Truffle.png';
import BotsAndArtificialIntelligence from 'assets/main/services/popup/BotsAndArtificialIntelligence/BotsAndArtificialIntelligence.webp';
import Dialogflow from 'assets/main/services/popup/BotsAndArtificialIntelligence/Dialogflow.png';
import Keras from 'assets/main/services/popup/BotsAndArtificialIntelligence/Keras.png';
import MicrosoftBotFW from 'assets/main/services/popup/BotsAndArtificialIntelligence/MicrosoftBotFW.png';
import NLTK from 'assets/main/services/popup/BotsAndArtificialIntelligence/NLTK.png';
import OpenAI from 'assets/main/services/popup/BotsAndArtificialIntelligence/OpenAI.png';
import Python from 'assets/main/services/popup/BotsAndArtificialIntelligence/Python.png';
import Rasa from 'assets/main/services/popup/BotsAndArtificialIntelligence/Rasa.png';
import TensorFlow from 'assets/main/services/popup/BotsAndArtificialIntelligence/TensorFlow.png';
import CRMAndERPSystems from 'assets/main/services/popup/CRMAndERPSystems/CRMAndERPSystems.webp';
import MicrosoftDynamics from 'assets/main/services/popup/CRMAndERPSystems/MicrosoftDynamics.png';
import NetSuite from 'assets/main/services/popup/CRMAndERPSystems/NetSuite.png';
import Odoo from 'assets/main/services/popup/CRMAndERPSystems/Odoo.png';
import Oracle from 'assets/main/services/popup/CRMAndERPSystems/Oracle.png';
import SAP from 'assets/main/services/popup/CRMAndERPSystems/SAP.png';
import Salesforce from 'assets/main/services/popup/CRMAndERPSystems/Salesforce.png';
import ZohoCRM from 'assets/main/services/popup/CRMAndERPSystems/ZohoCRM.png';
import AWS from 'assets/main/services/popup/CloudAndDataMigration/AWS.png';
import Ansible from 'assets/main/services/popup/CloudAndDataMigration/Ansible.png';
import Azure from 'assets/main/services/popup/CloudAndDataMigration/Azure.png';
import CloudAndDataMigration from 'assets/main/services/popup/CloudAndDataMigration/CloudAndDataMigration.webp';
import CloudAndDataMigrationMV from 'assets/main/services/popup/CloudAndDataMigration/CloudAndDataMigrationMV.webp';
import Docker from 'assets/main/services/popup/CloudAndDataMigration/Docker.png';
import GoogleCloud from 'assets/main/services/popup/CloudAndDataMigration/GoogleCloud.png';
import Kubernetes from 'assets/main/services/popup/CloudAndDataMigration/Kubernetes.png';
import Terraform from 'assets/main/services/popup/CloudAndDataMigration/Terraform.png';
import VMware from 'assets/main/services/popup/CloudAndDataMigration/VMware.png';
import BigCommerce from 'assets/main/services/popup/ECommercePlatforms/BigCommerce.png';
import ECommercePlatforms from 'assets/main/services/popup/ECommercePlatforms/ECommercePlatforms.webp';
import Magento from 'assets/main/services/popup/ECommercePlatforms/Magento.png';
import PayPal from 'assets/main/services/popup/ECommercePlatforms/PayPal.png';
import Shopify from 'assets/main/services/popup/ECommercePlatforms/Shopify.png';
import Stripe from 'assets/main/services/popup/ECommercePlatforms/Stripe.png';
import WooCommerce from 'assets/main/services/popup/ECommercePlatforms/WooCommerce.png';
import AWSRoute53 from 'assets/main/services/popup/HostingAndDomainManagement/AWSRoute53.png';
import Apache from 'assets/main/services/popup/HostingAndDomainManagement/Apache.png';
import Cloudflare from 'assets/main/services/popup/HostingAndDomainManagement/Cloudflare.png';
import DNS from 'assets/main/services/popup/HostingAndDomainManagement/DNS.png';
import HostingAndDomainManagement from 'assets/main/services/popup/HostingAndDomainManagement/HostingAndDomainManagement.webp';
import Nginx from 'assets/main/services/popup/HostingAndDomainManagement/Nginx.png';
import SSL from 'assets/main/services/popup/HostingAndDomainManagement/SSL.png';
import WHM from 'assets/main/services/popup/HostingAndDomainManagement/WHM.png';
import cPanel from 'assets/main/services/popup/HostingAndDomainManagement/cPanel.png';
import Chef from 'assets/main/services/popup/ITSupportAndMaintenance/Chef.png';
import ITSupportAndMaintenancePopup from 'assets/main/services/popup/ITSupportAndMaintenance/ITSupportAndMaintenance.webp';
import JiraServiceDesk from 'assets/main/services/popup/ITSupportAndMaintenance/JiraServiceDesk.png';
import ManageEngine from 'assets/main/services/popup/ITSupportAndMaintenance/ManageEngine.png';
import Puppet from 'assets/main/services/popup/ITSupportAndMaintenance/Puppet.png';
import ServiceNow from 'assets/main/services/popup/ITSupportAndMaintenance/ServiceNow.png';
import SolarWinds from 'assets/main/services/popup/ITSupportAndMaintenance/SolarWinds.png';
import Zendesk from 'assets/main/services/popup/ITSupportAndMaintenance/Zendesk.png';
import Flutter from 'assets/main/services/popup/MobileApplications/Flutter.png';
import Java from 'assets/main/services/popup/MobileApplications/Java.png';
import Kotlin from 'assets/main/services/popup/MobileApplications/Kotlin.png';
import MobileApplicationsRobot from 'assets/main/services/popup/MobileApplications/MobileApplications.webp';
import MobileApplicationsRobotMV from 'assets/main/services/popup/MobileApplications/MobileApplicationsRobotMV.webp';
import Objective from 'assets/main/services/popup/MobileApplications/Objective C.png';
import ReactNative from 'assets/main/services/popup/MobileApplications/ReactNative.png';
import Swift from 'assets/main/services/popup/MobileApplications/Swift.png';
import Xamarin from 'assets/main/services/popup/MobileApplications/Xamarin.png';
import ELKStack from 'assets/main/services/popup/SystemModernizationAndAudit/ELKStack.png';
import Linux from 'assets/main/services/popup/SystemModernizationAndAudit/Linux.png';
import Nagios from 'assets/main/services/popup/SystemModernizationAndAudit/Nagios.png';
import Splunk from 'assets/main/services/popup/SystemModernizationAndAudit/Splunk.png';
import SystemModernizationAndAudit from 'assets/main/services/popup/SystemModernizationAndAudit/SystemModernizationAndAudit.webp';
import WindowsServer from 'assets/main/services/popup/SystemModernizationAndAudit/WindowsServer.png';
import Zabbix from 'assets/main/services/popup/SystemModernizationAndAudit/Zabbix.png';
import Angular from 'assets/main/services/popup/WebApplications/Angular.png';
import Django from 'assets/main/services/popup/WebApplications/Django.png';
import JavaScript from 'assets/main/services/popup/WebApplications/JavaScript.png';
import NodeJS from 'assets/main/services/popup/WebApplications/NodeJS.png';
import ReactJS from 'assets/main/services/popup/WebApplications/ReactJS.png';
import RubyOnRails from 'assets/main/services/popup/WebApplications/RubyOnRails.png';
import VueJS from 'assets/main/services/popup/WebApplications/VueJS.png';
import WebApplications from 'assets/main/services/popup/WebApplications/WebApplications.webp';
import WebApplicationsMV from 'assets/main/services/popup/WebApplications/WebApplicationsMV.webp';

export interface PopupInfo {
  title?: string;
  colorMode: 'dark' | 'light';
  popupBoxSX: SxProps;
  popupImageSX: SxProps;
  popupTextSX: SxProps;
  popupImage: string;
  popupImageMV?: string;
  popupDescription: string[];
  popupTechnologies: {
    popupTechnologiesIcon: string;
    popupTechnologiesText: string;
    width?: number;
    height?: number;
  }[];
}

export interface Services {
  id: number;
  title: string;
  description: string;
  image?: string;
  imageMV?: string;
  json?: string;
  mainBoxSX?: SxProps;
  imageBoxSX?: SxProps;
  textBoxSX?: SxProps;
  jsonStyle?: SxProps;
  textSX?: SxProps;
  textTitleSX?: SxProps;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  popupInfo: PopupInfo;
  popupInfoSecond?: PopupInfo;
}

export const services: Services[] = [
  {
    id: 1,
    title: 'E-commerce Platforms',
    description:
      'Secure e-commerce platforms enabling smooth B2C, B2B, B2G, and C2C transactions with a focus on security and reliability',
    image: ECommerce,
    icon: PlusWhite,
    mainBoxSX: { backgroundColor: theme.palette.orange.main },
    imageBoxSX: {
      maxWidth: { xs: '278px', md: '319px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: { xs: '-24px', md: '0px' },
      left: '50%',
      transform: 'translateX(-50%)',
    },
    textSX: { color: theme.palette.text.primary },
    textTitleSX: { color: theme.palette.text.primary },
    popupInfo: {
      colorMode: 'light',
      popupBoxSX: { minHeight: { xs: '701px', md: '754px' } },
      popupImageSX: {
        position: 'absolute',
        maxWidth: '655px',
        width: '100%',
        height: 'auto',
        top: { xs: '281px', md: '245px' },
      },
      popupTextSX: {},
      popupImage: ECommercePlatforms,
      popupDescription: [
        'Secure e-commerce platforms for seamless B2C, B2B, B2G, and C2C  transactions',
        'We build robust e-commerce solutions tailored to your business  model, ensuring secure, efficient, and user-friendly online transactions',
        'Our  platforms support various e-commerce segments, allowing you to reach a  global audience while maintaining high standards of security and  performance',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: Magento,
          popupTechnologiesText: 'Magento',
        },
        {
          popupTechnologiesIcon: Shopify,
          popupTechnologiesText: 'Shopify',
        },
        {
          popupTechnologiesIcon: WooCommerce,
          popupTechnologiesText: 'WooCommerce',
          width: 34,
        },
        {
          popupTechnologiesIcon: Stripe,
          popupTechnologiesText: 'Stripe',
        },
        {
          popupTechnologiesIcon: PayPal,
          popupTechnologiesText: 'PayPal',
        },
        {
          popupTechnologiesIcon: BigCommerce,
          popupTechnologiesText: 'BigCommerce',
        },
        {
          popupTechnologiesIcon: Salesforce,
          popupTechnologiesText: 'Salesforce',
        },
      ],
    },
  },
  {
    id: 2,
    title: 'Hosting and Domain Management',
    description:
      'Reliable hosting and domain management ensuring continuous uptime and data security',
    json: 'HostingAndDomainManagement.json',
    icon: PlusBlack,
    mainBoxSX: { backgroundColor: theme.palette.grey[300] },
    jsonStyle: {
      maxWidth: '415px',
      width: '100%',
      position: 'absolute',
      bottom: '42px',
      left: '0px',
    },
    popupInfo: {
      colorMode: 'dark',
      popupBoxSX: {},
      popupImageSX: {
        position: 'absolute',
        maxWidth: '655px',
        width: { xs: '130%', md: '100%' },
        height: 'auto',
        top: '282px',
      },
      popupTextSX: {},
      popupImage: HostingAndDomainManagement,
      popupDescription: [
        'Reliable hosting and domain management for uninterrupted availability and  data protection',
        'We offer secure and reliable hosting services that ensure your  website or application is always available and performs optimally',
        'Additionally,  our domain management services include registration, renewal, and DNS  management, all designed to protect your online presence',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: cPanel,
          popupTechnologiesText: 'cPanel',
          width: 30,
        },
        {
          popupTechnologiesIcon: WHM,
          popupTechnologiesText: 'WHM',
        },
        {
          popupTechnologiesIcon: Apache,
          popupTechnologiesText: 'Apache',
        },
        {
          popupTechnologiesIcon: Nginx,
          popupTechnologiesText: 'Nginx',
        },
        {
          popupTechnologiesIcon: Cloudflare,
          popupTechnologiesText: 'Cloudflare',
          height: 14,
          width: 32,
        },
        {
          popupTechnologiesIcon: SSL,
          popupTechnologiesText: 'SSL',
          width: 36,
        },
        {
          popupTechnologiesIcon: DNS,
          popupTechnologiesText: 'DNS',
        },
        {
          popupTechnologiesIcon: AWSRoute53,
          popupTechnologiesText: 'AWSRoute53',
        },
      ],
    },
  },
  {
    id: 3,
    title: 'CRM & ERP Systems',
    description:
      'Tailored CRM and ERP systems for efficient resource management, process automation, and business integration',
    image: CRM,
    icon: PlusBlack,
    mainBoxSX: { backgroundColor: theme.palette.grey[300] },
    imageBoxSX: {
      maxWidth: { xs: '343px', md: '410px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      left: 0,
    },
    popupInfo: {
      colorMode: 'dark',
      popupBoxSX: { minHeight: { xs: '712px', md: '754px' } },
      popupImageSX: {
        position: 'absolute',
        maxWidth: '655px',
        width: '100%',
        height: 'auto',
        top: { xs: '221px', md: '186px' },
      },
      popupTextSX: {},
      popupImage: CRMAndERPSystems,
      popupDescription: [
        'Tailored CRM and ERP systems for resource management, data automation,  and enterprise integration',
        'We design and implement customized CRM and  ERP systems that align with your business processes, helping you manage  resources, automate workflows, and integrate all aspects of your enterprise  seamlessly',
        'Our solutions are aimed at improving efficiency, reducing costs,  and enabling better decision-making',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: Salesforce,
          popupTechnologiesText: 'Salesforce',
        },
        {
          popupTechnologiesIcon: MicrosoftDynamics,
          popupTechnologiesText: 'MicrosoftDynamics',
          width: 12.5,
        },
        {
          popupTechnologiesIcon: SAP,
          popupTechnologiesText: 'SAP',
          width: 40.43,
        },
        {
          popupTechnologiesIcon: Oracle,
          popupTechnologiesText: 'Oracle',
        },
        {
          popupTechnologiesIcon: Odoo,
          popupTechnologiesText: 'Odoo',
        },
        {
          popupTechnologiesIcon: ZohoCRM,
          popupTechnologiesText: 'ZohoCRM',
          width: 31.67,
        },
        {
          popupTechnologiesIcon: NetSuite,
          popupTechnologiesText: 'NetSuite',
        },
      ],
    },
  },
  {
    id: 4,
    title: 'Mobile & Web Applications',
    description:
      'Custom iOS, Android, cross-platform apps, and responsive web solutions designed to meet your business needs and scale efficiently',
    image: MobileWebApplications,
    imageMV: MobileWebApplicationsMV,
    icon: PlusBlack,
    mainBoxSX: {
      maxWidth: { xs: '343px', md: '850px' },
      height: { xs: '343px', md: '778px' },
      backgroundColor: theme.palette.grey[300],
    },
    imageBoxSX: {
      maxWidth: '826px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      left: 0,
    },
    popupInfo: {
      title: 'Web Applications',
      colorMode: 'light',
      popupBoxSX: { minHeight: { xs: '804px', md: '754px' } },
      popupImageSX: {
        maxWidth: { xs: '311px', md: '495px' },
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: { xs: '68px', md: '76px' },
        left: '50%',
        transform: 'translateX(-50%)',
      },
      popupTextSX: {
        mt: '224px',
      },
      popupImage: WebApplications,
      popupImageMV: WebApplicationsMV,

      popupDescription: [
        'Responsive, scalable web solutions to automate processes and engage your  target audience',
        'Our team develops web applications that are not only  responsive and user-friendly but also scalable to meet growing business  demands',
        'We focus on creating modular and adaptive web applications that  streamline your business processes, enhance user engagement, and support  your digital transformation efforts',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: JavaScript,
          popupTechnologiesText: 'JavaScript',
        },
        {
          popupTechnologiesIcon: Angular,
          popupTechnologiesText: 'Angular',
        },
        {
          popupTechnologiesIcon: ReactJS,
          popupTechnologiesText: 'ReactJS',
        },
        {
          popupTechnologiesIcon: NodeJS,
          popupTechnologiesText: 'NodeJS',
        },
        {
          popupTechnologiesIcon: Django,
          popupTechnologiesText: 'Django',
        },
        {
          popupTechnologiesIcon: RubyOnRails,
          popupTechnologiesText: 'RubyOnRails',
        },
        {
          popupTechnologiesIcon: VueJS,
          popupTechnologiesText: 'VueJS',
        },
      ],
    },
    popupInfoSecond: {
      title: 'Mobile Applications',
      colorMode: 'light',
      popupBoxSX: { minHeight: { xs: '651px', md: '754px' } },
      popupImageSX: {
        maxWidth: { xs: '100', md: '100%' },
        width: { xs: '265px', md: '100%' },
        height: 'auto',
        position: 'absolute',
        top: '0px',
        left: '0px',
        borderTopLeftRadius: '20px',
      },
      popupTextSX: {
        mt: { xs: '-10px', md: '108px' },
      },
      popupImage: MobileApplicationsRobot,
      popupImageMV: MobileApplicationsRobotMV,
      popupDescription: [
        'Custom iOS, Android, and cross-platform apps tailored for your business  needs',
        'We specialize in creating intuitive and engaging mobile applications  that are designed to perform across various platforms',
        'Whether you require a  native iOS app, an Android solution, or a cross-platform application, we ensure  that your mobile product meets your specific business objectives, offers a  seamless user experience, and aligns with the latest industry standards',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: Swift,
          popupTechnologiesText: 'Swift',
        },
        {
          popupTechnologiesIcon: Kotlin,
          popupTechnologiesText: 'Kotlin',
        },
        {
          popupTechnologiesIcon: Xamarin,
          popupTechnologiesText: 'Xamarin',
        },
        {
          popupTechnologiesIcon: Objective,
          popupTechnologiesText: 'Objective',
        },
        {
          popupTechnologiesIcon: Java,
          popupTechnologiesText: 'Java',
        },
        {
          popupTechnologiesIcon: Flutter,
          popupTechnologiesText: 'Flutter',
        },
        {
          popupTechnologiesIcon: ReactNative,
          popupTechnologiesText: 'ReactNative',
        },
      ],
    },
  },
  {
    id: 5,
    title: 'Bots and AI',
    description:
      'AI-powered chatbots and systems using machine learning and NLP for automated customer interactions',
    image: BotsAndAI,

    icon: PlusOrange,
    mainBoxSX: { overflow: 'none', backgroundColor: theme.palette.black.main },
    imageBoxSX: {
      maxWidth: { xs: '230px', md: '351px', xxl: '392px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: { xs: '0px', md: '-5px' },
      right: { xs: '0px', md: '-76px', xxl: '-146px' },
    },
    textBoxSX: { width: '100%' },
    textSX: { color: theme.palette.text.primary, width: { xs: '100%', md: '153px' } },
    textTitleSX: { color: theme.palette.text.primary, width: { xs: '100%', md: '200px' } },
    popupInfo: {
      colorMode: 'light',
      popupBoxSX: { minHeight: '754px' },
      popupImageSX: {
        maxWidth: '525px',
        width: '100%',
        height: 'auto',
        position: 'absolute',
        bottom: { xs: '158px', md: '114px' },
        right: '0px',
      },
      popupTextSX: {},
      popupImage: BotsAndArtificialIntelligence,
      popupDescription: [
        'AI-driven chatbots and systems using machine learning and NLP for  automated customer interactions',
        'We develop intelligent bots and AI systems  that automate customer service, enhance user engagement, and provide real time assistance',
        'Our solutions leverage advanced machine learning algorithms  and natural language processing NLP to deliver accurate and context-aware  responses',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: TensorFlow,
          popupTechnologiesText: 'TensorFlow',
        },
        {
          popupTechnologiesIcon: Keras,
          popupTechnologiesText: 'Keras',
        },
        {
          popupTechnologiesIcon: Python,
          popupTechnologiesText: 'Python',
        },
        {
          popupTechnologiesIcon: Rasa,
          popupTechnologiesText: 'Rasa',
        },
        {
          popupTechnologiesIcon: Dialogflow,
          popupTechnologiesText: 'Dialogflow',
        },
        {
          popupTechnologiesIcon: MicrosoftBotFW,
          popupTechnologiesText: 'MicrosoftBotFW',
        },
        {
          popupTechnologiesIcon: NLTK,
          popupTechnologiesText: 'NLTK',
        },
        {
          popupTechnologiesIcon: OpenAI,
          popupTechnologiesText: 'OpenAI',
        },
      ],
    },
  },
  {
    id: 6,
    title: 'Blockchain Development',
    description: 'Smart contracts, DeFi, and DAPPs for building secure, decentralized applications',
    image: BlockchainDevelopment,
    imageMV: BlockchainDevelopmentMV,
    icon: PlusBlack,
    mainBoxSX: { backgroundColor: theme.palette.grey[300] },
    imageBoxSX: {
      maxWidth: { xs: '247px', md: '346px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: { xs: '0px', md: '-80px' },
      right: { xs: '0px', md: '-33px' },
    },
    popupInfo: {
      colorMode: 'dark',
      popupBoxSX: { minHeight: { xs: '653px', md: '754px' } },

      popupImageSX: {
        maxWidth: '655px',
        width: '100%',
        height: 'auto',
        position: 'absolute',
        bottom: { xs: '170px', md: '105px' },
      },
      popupTextSX: {},
      popupImage: BlockchainDevelopmentPopup,
      popupDescription: [
        'Smart contracts, DeFi, and DAPPs for pioneering decentralized solutions',
        'Our  blockchain development services include creating secure and scalable  decentralized applications DAPPs, implementing smart contracts, and  developing DeFi platforms',
        'We help businesses harness the power of  blockchain technology to innovate and stay ahead in the decentralized  ecosystem',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: Ethereum,
          popupTechnologiesText: 'Ethereum',
        },
        {
          popupTechnologiesIcon: Solidity,
          popupTechnologiesText: 'Solidity',
        },
        {
          popupTechnologiesIcon: Hyperledger,
          popupTechnologiesText: 'Hyperledger',
        },
        {
          popupTechnologiesIcon: Truffle,
          popupTechnologiesText: 'Truffle',
        },
        {
          popupTechnologiesIcon: Chainlink,
          popupTechnologiesText: 'Chainlink',
        },
        {
          popupTechnologiesIcon: Corda,
          popupTechnologiesText: 'Corda',
        },
        {
          popupTechnologiesIcon: EOS,
          popupTechnologiesText: 'EOS',
        },
        {
          popupTechnologiesIcon: Polkadot,
          popupTechnologiesText: 'Polkadot',
        },
      ],
    },
  },
  {
    id: 7,
    title: 'Cloud and Data Migration',
    description:
      'Efficient cloud migration services for optimized performance, cost reduction, and secure data transfer',
    json: 'CloudAndDataMigration.json',
    icon: PlusBlack,
    mainBoxSX: { backgroundColor: theme.palette.grey[300] },
    jsonStyle: {
      maxWidth: '415px',
      width: '100%',
      position: 'absolute',
      bottom: { xs: '0px', md: '-42px' },
      left: '0px',
    },
    popupInfo: {
      colorMode: 'light',
      popupBoxSX: { minHeight: { xs: '700px', md: '754px' } },
      popupImageSX: {
        maxWidth: { xs: '202px', md: '392px' },
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '0px',
        right: { xs: '50%', md: '25px' },
        transform: { xs: 'translateX(50%)', md: 'none' },
      },
      popupTextSX: {},
      popupImage: CloudAndDataMigration,
      popupImageMV: CloudAndDataMigrationMV,
      popupDescription: [
        'Efficient cloud migration services for optimized costs, performance, and  secure data transfer',
        'Our cloud migration services ensure a smooth transition  of your data and applications to the cloud, optimizing performance, reducing  costs, and improving security',
        'We provide detailed planning, execution, and  post-migration support to minimize downtime and ensure a successful  migration',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: AWS,
          popupTechnologiesText: 'AWS',
        },
        {
          popupTechnologiesIcon: Azure,
          popupTechnologiesText: 'Azure',
        },
        {
          popupTechnologiesIcon: GoogleCloud,
          popupTechnologiesText: 'GoogleCloud',
        },
        {
          popupTechnologiesIcon: Docker,
          popupTechnologiesText: 'Docker',
        },
        {
          popupTechnologiesIcon: Kubernetes,
          popupTechnologiesText: 'Kubernetes',
        },
        {
          popupTechnologiesIcon: VMware,
          popupTechnologiesText: 'VMware',
        },
        {
          popupTechnologiesIcon: Terraform,
          popupTechnologiesText: 'Terraform',
        },
        {
          popupTechnologiesIcon: Ansible,
          popupTechnologiesText: 'Ansible',
        },
      ],
    },
  },
  {
    id: 8,
    title: 'IT Support and Maintenance',
    description: 'Proactive IT support to ensure smooth operations and minimize risks',
    image: ITSupportAndMaintenance,
    icon: PlusOrange,
    mainBoxSX: {
      overflow: { xs: 'hidden', md: 'visible' },
      backgroundColor: theme.palette.black.main,
    },
    imageBoxSX: {
      maxWidth: { xs: '343px', md: '414px' },
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
    },
    textBoxSX: { color: theme.palette.text.primary, alignSelf: 'end' },
    textSX: { width: '302px' },
    popupInfo: {
      colorMode: 'light',
      popupBoxSX: { minHeight: '754px' },
      popupImageSX: {
        maxWidth: '521px',
        width: { xs: '125%', md: '100%' },
        position: 'absolute',
        bottom: '0px',
        left: { xs: '5px', md: '89px' },
      },
      popupTextSX: {},
      popupImage: ITSupportAndMaintenancePopup,
      popupDescription: [
        'Proactive IT support to ensure stable operations and minimize risks',
        'Our IT  support and maintenance services are designed to keep your systems running  smoothly, prevent downtime, and quickly resolve any issues that arise',
        'We  offer proactive monitoring, regular updates, and 24/7 support to ensure your  IT infrastructure remains secure and efficient',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: Zendesk,
          popupTechnologiesText: 'Zendesk',
        },
        {
          popupTechnologiesIcon: JiraServiceDesk,
          popupTechnologiesText: 'JiraServiceDesk',
        },
        {
          popupTechnologiesIcon: SolarWinds,
          popupTechnologiesText: 'SolarWinds',
        },
        {
          popupTechnologiesIcon: ServiceNow,
          popupTechnologiesText: 'ServiceNow',
        },
        {
          popupTechnologiesIcon: ManageEngine,
          popupTechnologiesText: 'ManageEngine',
        },
        {
          popupTechnologiesIcon: Puppet,
          popupTechnologiesText: 'Puppet',
        },
        {
          popupTechnologiesIcon: Chef,
          popupTechnologiesText: 'Chef',
        },
      ],
    },
  },
  {
    id: 9,
    title: 'System Modernization and Audit',
    description:
      'System audits and upgrades to enhance security, performance, and infrastructure efficiency',
    json: 'SystemModernizationAndAudit.json',
    icon: PlusBlack,
    mainBoxSX: { backgroundColor: theme.palette.grey[300] },
    jsonStyle: {
      maxWidth: { xs: '266px', md: '303px' },
      width: '100%',
      position: 'absolute',
      bottom: { xs: '16px', md: '0px' },
      right: '29px',
    },
    popupInfo: {
      colorMode: 'light',
      popupBoxSX: {},
      popupImageSX: {
        maxWidth: '594px',
        width: '100%',
        height: 'auto',
        position: 'absolute',
        left: '50%',
        bottom: { xs: '193px', md: '120px' },
        transform: 'translateX(-50%)',
      },
      popupTextSX: {},
      popupImage: SystemModernizationAndAudit,
      popupDescription: [
        'System upgrades and audits for enhanced security, performance, and  infrastructure efficiency',
        'We conduct thorough audits of your existing IT  infrastructure and provide recommendations for modernization',
        'Our services  include upgrading outdated systems, enhancing security protocols, and  optimizing your IT resources to improve overall performance and efficiency',
      ],
      popupTechnologies: [
        {
          popupTechnologiesIcon: WindowsServer,
          popupTechnologiesText: 'WindowsServer',
        },
        {
          popupTechnologiesIcon: Linux,
          popupTechnologiesText: 'Linux',
        },
        {
          popupTechnologiesIcon: Nagios,
          popupTechnologiesText: 'Nagios',
        },
        {
          popupTechnologiesIcon: Zabbix,
          popupTechnologiesText: 'Zabbix',
        },
        {
          popupTechnologiesIcon: Splunk,
          popupTechnologiesText: 'Splunk',
        },
        {
          popupTechnologiesIcon: ELKStack,
          popupTechnologiesText: 'ELKStack',
        },
        {
          popupTechnologiesIcon: Docker,
          popupTechnologiesText: 'Docker',
        },
        {
          popupTechnologiesIcon: Ansible,
          popupTechnologiesText: 'Ansible',
        },
      ],
    },
  },
];
